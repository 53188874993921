.landing-alert{
    margin-bottom: 0;
}
.page-header{
    background-color: #B2AFAB;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter::after{
        //  background-color: rgba(0, 0, 0, 0.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }

    &.section-dark{
        .content-center,
        .category-absolute{
            width: 50%;
            z-index: 2;
        }
    }
}

.landing-section{
    padding: 100px 0;
    z-index: 1;
}
.section-buttons{
    z-index: 1;
}
.landing-section .btn-simple{
    padding: 0;
}
.landing-section .column{
    padding: 0 75px 0 25px;
}
.team-player .img-circle, .team-player .img-thumbnail{
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}
// .contact-form{
//     margin-top: 30px;
// }
.required:after {
    content:" *";
    color: red;
  }
.contact-form label{
    margin-top: 15px;
}
.contact-form .btn{
  margin: 5px;
}

.navbar-relative{
    position: relative;
    z-index: 2;
}
#register-navbar a{
    color: #FFF;
}
.register-background .container{
    margin-top: 11%;
    position: relative;
    z-index: 3;
}
.register-footer{
    bottom: 20px;
    position: absolute;
    z-index: 1;
    width: 100%;
    background: transparent;
    color: $white-color;
}
.register-footer .fa-heart{
    color: #EB5E28;
}

.register-card label{
    margin-top: 15px;
}
.register-card .title{
    color: #B33C12;
    text-align: center;
}
.register-card .btn{
    margin-top: 30px;
}
.register-card .forgot{
    text-align: center;
}

.profile-content{
    position: relative;
}
.owner{
    text-align: center;
}
.owner .avatar{
    padding: 15px;
    max-width: 180px;
    margin: -85px auto 0;
    display: inline-block;
}
.owner .name h4{
    margin-top: 10px;
}
.profile-tabs{
    margin: 50px 0;
    min-height: 300px;
}
#following h3{
    margin: 20px 0 40px 0;
}

#follows .follows .unfollow .checkbox{
    margin-top: -15px;
}
#follows .follows h6{
    margin-top: 15px;
}
.follows hr{
    margin-top: 10px;
}
.alert h5{
    // margin-bottom: 10px;
}
.title-brand{
    max-width: 960px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    color: #FFFFFF;
    display: block;

    .type{
        position: absolute;
        font-size: 20px;
        background: #132026;
        padding: 6px 10px;
        border-radius: 4px;
        top: 0;
        font-weight: 600;
        margin-top: 10px;
        right: -15px;
    }
}

.presentation-title{
    font-size: 8em;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    background: #fbf8ec;
    background: -moz-linear-gradient(top, #FFFFFF 35%, #4e6773 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 35%,#4e6773 100%);
    background: -ms-linear-gradient(top, #FFFFFF 35%,#4e6773 100%);
    background: linear-gradient(to bottom, #FFFFFF 35%,#4e6773 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.presentation-subtitle{
    font-family:$font-family-IBM-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 73px;
    text-align: center;
    color: #FFFFFF;
}

.category-absolute{
    position: absolute;
    text-align: center;
    top: 100vh;
    margin-top: -60px;
    padding: 0 15px;
    width: 100%;
    color: rgba(255, 255, 255, 0.3);

    .creative-tim-logo {
        max-width: 140px;
        top: -2px;
        left: 3px;
        position: relative;
    }
}

.fog-low{
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -35%;
    margin-bottom: -50px;
    width: 110%;
    opacity: .85;

    img{
        width: 100%;
    }

    &.right{
        margin-left: 30%;
        opacity: 1;
    }
}
.page-header{
    .content-center{
        // margin-top: 85px;
    }
}
.sticky-box{
    position: sticky;
    top: 0%;
    background: #FFFFFF;
    z-index: 40;
   
}
.moving-clouds{
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    -webkit-animation: cloudLoop 80s linear infinite;
    animation: cloudLoop 80s linear infinite;
}

@keyframes cloudLoop{   0%{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
                        100%{-webkit-transform:translate3d(-50%,0,0);
                        transform:translate3d(-50%,0,0)}
                    }
.following{
    img{
        max-width: 70px;
    }
}


.profile-content{
    & .tab-content{
        & .tab-pane{
            min-height: 200px;
        }
        & #tweets, #connections, #media{
            height: 100%;
        }
    }
}

@mixin scroll-style($size: 10px,
  $thumb: none,
  $thumb-color: grey,
  $thumb-radius: 10px,
  $track-color: transparent,
  $track-radius: 10px) {

  // Respaldo para Firefox
  scrollbar-color: $thumb-color $track-color;
  scrollbar-width: thin;

  // Navegadores basados en webkit
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    &-track {
        background-color: $track-color;
        border-radius: $track-radius;
    }
    &-thumb {
      background-color: $thumb-color;
      background-image: $thumb;
      border-radius: $thumb-radius;
    }
  }
}


.scroll {
  @include scroll-style(
      $size: var(--scroll-size, 10px),

      $thumb:  var(--scroll-thumb, none),
      $thumb-color: var(--scroll-thumb-color, grey),
      $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
    
      $track-color: var(--scroll-track, transparent),
      $track-radius: var(--scroll-track-radius, var(--scroll-radius)),
    );
  
  // Ejemplo 1
  .scroll-main{
    --scroll-size: 10px;
    --scroll-radius: 10px;
    --scroll-track: rgb(0 0 0 / 40%);
    --scroll-thumb-color: #dfdfdf;
  }

}

// Solo demo


.custom-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
  > * {
    // height:700px;
    overflow-y: auto;
    
  }
  ::-webkit-scrollbar {
    width: 10px;
}
 
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #bcbab6; 
}
}

.home-filters{
    background: rgba(0, 0, 0, 0.7);
    border: 2px solid rgba(255, 255, 255, 0.6) !important;
    border-radius: 10px; 
}
.info-btn-bottom{
    position: absolute;
    bottom: 5%;
    right: 5%;
}
.stech-text{
font-size: 16px;
color: $stech-color;
font-family: "IBM Plex Sans Condensed", sans-serif;
}
.search-bg{
    padding: 20px;
    height: 80px;
    border: #E6E6E6 1px solid;
}
.sidebar{

    border-top: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
    border-bottom: 1px solid rgb(221, 221, 221);
    padding: 20px;
    width: 100%;
}

.main-page-logo{
    position: absolute;
    color: #FFFFFF;
    size: 36px;
    font-style: italic;
    left: 5%;
    bottom: 5%;
    z-index: 99;
    user-select: none;
}
.pdf_icons{
    border-radius: 50%;
    padding-right: 2px;
    color:#000000;
    background: #E3E3E3; //
    padding: 4px;
}
.bulk_icons{
    color: #36454F;

}
.pdf_main{
     min-height: 80vh;
    // padding: 40px 80px 40px 80px;
    // background: #D9D9D9;
    border: #D9D9D9 2px solid;
}
.pdf_heading{
    color: #1A1A1A;
    font-size: 18px;
    padding-left: 8px;
    padding-top: 15px;
    line-height: 22px;
}

    .header-buttons{
        border-radius:36px !important;
         height:43 !important;
         color: #36454f !important;;
    }
.detail_header{
    display: flex;
    // justify-content: space-between;
    
} 
.detail_info_heading{
    color: #9F9F9F;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}    
.detail_info_label{
    display: contents;
    font-family: inherit;
    color: #1A1A1A;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-right: 20%;
}
.head{
    padding: 5px;
    border: 1px solid rgb(228, 223, 223);
}
.center-pagination{
    width: 100%;
    text-align: center;
}
.content-border{border: "#36454F 1px solid";
    border-radius: "5px";
    padding:10px}
.content-bg-1{
    background: linear-gradient(
        to right, 
        #ffffff 0%, 
        #ffffff 50%, 
        rgba(54, 69, 79, 0.3) 50%, 
        rgba(54, 69, 79, 0.3) 100%
      )!important;;
}
.content-bg-2{
    background: linear-gradient(
        to left, 
        #ffffff 0%, 
        #ffffff 50%, 
        rgba(54, 69, 79, 0.3) 50%, 
        rgba(54, 69, 79, 0.3) 100%
      ) !important;
  }.card4 {
    position: absolute;
    top: 103%;
    right: 3%;
    bottom: 14%;
    width: 250px;
    margin-top: 4px;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 26px;
    padding: 8px 23px 15px;
    z-index: 2;
    text-decoration: none;
    overflow: hidden;
    border: 5px solid #cccccc;
    
    
  
    &:hover {
    //   border: 1px solid #CD3D73;
    }
    
    h3 {margin-top: 8px;}
  }
  
  .card4:hover {
    .go-corner {
      margin-right: -12px;
    }
    .go-arrow {
      opacity: 1;
    }
  }

.home{
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: inherit;
color: #8C0001;
margin-left: .5rem;
 
}

.search_result{
    font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
font-family: inherit;
color: #9F9F9F;
} 
.powered-by{ 
    font-weight: 500;
   font-size: 24px;
   user-select: none;
   }
   
   .drag-drop{
        
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   text-align: center;
   }

  .loader-position{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 99;

  } 
  .loader-listing{
    background-color: rgb(0 0 0 / 17%);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;

  } 
  .loader-center{
    position: absolute;
    top: 60%;
    left: 37%;

  }
  .how-to-useBtn{
    cursor: pointer !important;
    position: absolute;
    z-index: 99;
    width: 190px;
    right: 70px; 
    border: white 2px solid;
    height: 56px;  
    font-size: 16px;
    font-weight: 500;
    bottom: 8px;
    background: #36454F;
    text-transform: uppercase;
   }
   .paragraph_drag{
    font-weight: 600;
    padding-top: 36px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    }

    .para_OR{
    margin-top:16px ;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
}


    .filter_text{

    font-size: 14px;
    line-height: 17px;
    font-weight: 600px;
    }

    .filter_badge{
    border-radius: 12px;
    height: 23px;
    }
 .pointer-hand{
    cursor: pointer;
 }   
  .transform-component-module_wrapper__1_Fgj{
    overflow: visible !important;
 }
  .filter_fields{
    padding-right: 1rem !important;
 }



 .eyeIcon{
    position: absolute;
    right: 12%;
    bottom: 29%;
    z-index: 44;
  }

  .error-message{

    border-radius: 5px;
    color: white;
    background-color:#be0d0d !important ;
    text-align: center;
  }
  .Toastify__toast-container--top-right{
    top: 6em !important;
  } 