.label-dropbox{
    background-color: #ffffff !important;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    padding: 5px 10px 0;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    }
    .label-header{
      font-size: 12px;
     
      font-weight: bold;
      text-align: start;
      line-height: 15px;
      color: #FFFFFF;
      opacity: 0.6;
      margin: 0;
      margin-bottom: 2px;
      display: block !important;
      white-space: nowrap;
      position: relative;
    }

 .label-search{
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    color: #cccccc;
 }   
 .vl{
    border-left: 1px solid #cccccc;
  height: 70%;
  margin: 0px 7px;
  float: left;
 }
 .collapse{
    overflow: hidden;
    transition: 0.4s max-height;
 }
 .widget {
   transition: all 0.8s ease;
   overflow: hidden;
 }