.footer{
    border-top: #E6E6E6 1px solid;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    line-height: 20px;
    font-size: 18px;
    width: 100%;
    z-index: 1;
    height: 45px;
    padding: 5px;
    color: #36454F;

}