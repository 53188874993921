.card-plain {
  background-color: #FFFFFF;
  padding:17px;
  margin-bottom: 0;
  box-shadow: 3px 6px 10px -4px rgb(0 0 0 / 15%);
  border-bottom: #e4dfdf 1px solid;

  .card-body {
      padding-left: 5px;
      padding-right: 5px;
  }

  img {
      border-radius: $border-radius-extreme;
  }
  .card-body{
    padding: 10px;
  }
  .btn{
    padding: 0.1rem 8px;

  }
}
