.progress {
    background-color: $medium-gray;
    // border-radius: $border-radius-small;
    box-shadow: none;
    height: 5px;
  margin-left: 15%;
  margin-right: 15%;
}
.progress-thin{
    height: 4px;
}
.progress-bar{
    background-color: $primary-color;
}
.progress-bar-primary{
    background-color: $primary-color;
}
.progress-bar-secondary{
    background-color:red($color: #000000);
}
.progress-bar-info{
    background-color: #3A68DE;
}
.progress-bar-success{
    background-color: $success-color;
}
.progress-bar-warning{
    background-color: $warning-color;
}
.progress-bar-danger{
    background-color: $danger-color;
}
