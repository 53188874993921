.img-listing{
    object-fit: cover;
    object-fit: cover;
    height: 150px;
    margin: 8px 0px;
    width: 150px;
    margin-right: 0;
    padding-right: 0;
}
// .description{
//     margin-top: 12px;
//     font-size: 15px;
//     margin-bottom: 8px;
//     max-height: 5.19em;
// }

  
  .ld-loader {
    margin: 5% auto 30px;
  }
  
  .ld-book {
    border: 4px solid #FFFFFF;
    width: 60px;
    height: 45px;
    position: relative;
    perspective: 150px;
  }
  
  .ld-page {
    display: block;
    width: 30px;
    height: 45px;
    border: 4px solid #FFFFFF;
    border-left: 1px solid #932727;
    margin: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    overflow: hidden;
    background: #8C0001 !important;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
  
  .ld-book .ld-page:nth-child(1) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.6s infinite;
  }
  
  .ld-book .ld-page:nth-child(2) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.45s infinite;
  }
  
  .ld-book .ld-page:nth-child(3) {
    -webkit-animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
    animation: pageTurn 1.2s cubic-bezier(0, .39, 1, .68) 1.2s infinite;
  }
  
  
  /* Page turn */
  
  @-webkit-keyframes pageTurn {
    0% {
      -webkit-transform: rotateY( 0deg);
      transform: rotateY( 0deg);
    }
    20% {
      background: #8C0001;
    }
    40% {
      background: #8C0001;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
    100% {
      background: #8C0001;
      -webkit-transform: rotateY( -180deg);
      transform: rotateY( -180deg);
    }
  }
  
  @keyframes pageTurn {
    0% {
      transform: rotateY( 0deg);
    }
    20% {
      background: #4b1e77;
    }
    40% {
      background: #8C0001;
      transform: rotateY( -180deg);
    }
    100% {
      background: #8C0001;
      transform: rotateY( -180deg);
    }
  }
  
  
  /* Dots */
  
  @-webkit-keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }
  
  @keyframes Dots {
    0% {
      content: "";
    }
    33% {
      content: ".";
    }
    66% {
      content: "..";
    }
    100% {
      content: "...";
    }
  }