.main_bloc{
   background-color: #D9D9D9;
   margin-top: 10px;
   display: flexbox;
   border: 2px rgba(255, 255, 255, 0.7) dashed;
   padding: 1rem;
   font-size: 18px;
   border-radius: 20px;
   color: #000000
   ;
}

 .upload_btn{
  border: #8C0001 2px solid;
  color: #8C0001;
  margin-right: 5px;
  border-radius: 36px;
  width: 170px;
  height: 45px;
  padding: 0.4rem;
 }
 
  ::-webkit-file-upload-button {
    display: none;
 }